<template>
  <div>
    <div class="jumbotron-wrapper color">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5">
          <div class="row gx-5">
            <header class="mb-4">
              <p class="fs-1 fw-bolder mb-1">
                非凸科技受邀出席君华汇沙龙，探讨算法交易最佳实践
              </p>
              <div class="fst-italic mb-2">2023年7月18日</div>
              <a
                class="badge bg-secondary text-decoration-none link-light"
                href="#!"
                >新闻</a
              >
              <a
                class="ms-1 badge bg-secondary text-decoration-none link-light"
                href="#!"
                >君华私募基金汇</a
              >
            </header>
          </div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section class="py-5">
        <div class="container-lg px-4">
          <div class="row">
            <div class="col-lg-3">
              <div class="d-flex align-items-center mt-lg-5 mb-4">
                <img
                  class="img-fluid rounded-circle logo_50"
                  src="../../assets/img/logo/logo_50.jpeg"
                  alt="非凸科技"
                />
                <div class="ms-3">
                  <div class="fw-bold">非凸新闻</div>
                  <div class="text-muted">Non-convex News</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <article class="lh-lg">
                <section class="mb-5">
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/47/01-首图.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    近日，由君华私募基金汇主办的第四十七期君华汇“技术驱动创新，算法赋能交易”沙龙活动在成都顺利举行，国泰君安证券和非凸科技受邀出席，与在场嘉宾共同探讨算法交易的诸多优势和应用实践。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/47/02-彭玮.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    随着科技创新与赋能在金融市场中扮演着越来越重要的角色，算法交易也越来越受管理人的重视和青睐。对于他们来说，算法交易能为其带来降低交易成本、减少合规风险、提高胜率、平滑收益曲线等优势。此次活动，国泰君安证券机构客户部交易平台管理彭玮为管理人分享了算法交易的概念、场景、申请流程及绩效评价，非凸科技机构业务经理周升也详细介绍了算法交易的产品及服务等相关内容。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/47/03-周升.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    会上，周升表示，“大家都在追求更有效的交易执行方式，只有通过更智能的交易算法选择，才能为客户提供更精准、更便捷、更切实的价值与体验。非凸科技基于Rust语言构建了高安全性、稳定性、高并发、低延迟的算法交易系统，由顶尖的计算机工程及金融团队，通过使用大量的收敛函数和对于价格及波动率的结合预测，打磨出独特的非凸智能算法，已上线多家头部券商，实盘绩效表现优异，可以满足管理人对高收益、高稳定算法产品的迫切需求。”
                  </p>
                  <p>
                    非凸科技坚持通过绩效领先的算法生态、先进高效的系统模块以及雄厚的技术实力，为机构客户提供一站式算法业务解决方案，并通过丰富的联合展业资源，助力券商扩大机构业务规模和品牌影响力。未来，非凸科技将持续通过算法交易实践的积累，基于更懂业务的产品和服务以及更为前沿的技术，为量化行业生态建设赋能。
                  </p>
                  <p>
                    在交流环节，各机构就算法使用要求、算法策略容量、算法绩效表现、交易系统对接等问题进行了激烈讨论，周升对此也作出了细致的解答。
                  </p>
                  <p>
                    感谢盛邀！也期待未来有更多的行业交流，共享各方资源，促进多方合作，繁荣行业发展。
                  </p>
                </section>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "News47",
};
</script>

<style></style>
